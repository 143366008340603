@import url(https://fonts.googleapis.com/css?family=Space+Mono:400,700&subset=latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --green: #52875d;
  --blue: #3280c2;
  --red : #c63d43;
  --yellow : #dfdc02;
  --orange: #EF8F20;
  --lightblue: #66CDDB;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background-color: white;
  border: none;
}

button:active, button:focus {
  outline: none;
}

.design {
  --color: var(--green);
}

.technology {
  --color: var(--blue);
}

.about-us {
  --color: var(--red);
}

.contact-us {
  --color: var(--yellow);
}

.orange {
  --color: var(--orange);
}

.lightblue {
  --color: var(--lightblue);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
.NavBar {
  width: 100%;
  display: flex;
  position: fixed;
  z-index: 1;
}

.NavBar.WelcomePage {
  bottom: 0;
  padding-right: 8%;
}

.NavBar.WelcomePage .Tabs button:first-child {
  display: none;
}

.Tabs {
  display: flex;
  flex-grow: 1;
  min-height: 50px;
  align-items: center;
  max-width: 92%;
}

.Tab {
  display: flex;
  justify-content: center;
  width: 25%;
  height: 100%;
  color: white;
  color: var(--color, white);
  -webkit-transition: background 0.7s;
  transition: background 0.7s;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
}

.Tab:hover {
  color: white;
  background: white;
  background: var(--color, white);
}

.Tab.active {
  color: white;
  background: white;
  background: var(--color, white);
}

.PLogo{
  width: 24px;
}

.LangTabs {
  width: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  position: fixed;
  top: 0;
  right: 0;
}

.LangTabs button:first-child {
  border-bottom: 1px solid black;
}

.Lang {
  color: black;
  flex-grow: 1;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.Lang.active {
  color: #9e0000;
}

.Lang:hover {
  text-decoration: underline;
}

.logo {
  height: 12%;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .NavBar.WelcomePage button:first-child {
    display: flex;
  }
  .NavBar.WelcomePage button:first-child img {
    display: none;
  }

  .Tab {
    width: 20%;
    font-size: 1.2vw;
    padding: 1% 0;
  }

  .LangTabs {
    flex-direction: row;
    position: fixed;
    top: 0;
    right: 0;
    padding: .5% 0;
  }

  .LangTabs button:first-child {
    border-bottom: none;
    border-right: 1px solid black;
  }

  .Lang {
    font-size: .8vw;
    border-left: none;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
.ContactUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Top {
  display: flex;
  height: 50%;
  justify-content: space-around;
  align-items: center;
  padding: 0 4%;
  flex-direction: column;
}

.Bottom {
  height: 50%;
  width: 100%;
  display: none;
}

.ContactInfo {
  color: #969ca0;
  font-size: 18px;
  font-family: Space Mono;
  text-align: left;
}

.Info {
  display: flex;
  align-items: center;
  font-size: 11px;
}

.Info img {
  height: 100%;
}

.Info p {
  margin-left: 10px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Bottom {
    display: block;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .Top {
    flex-direction: row;
  }

  .ContactUs .Text {
  width: 47%;
  }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
.Section {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  color: var(--color);
  align-items: center;
  justify-content: center;
}

.Left {
  width: 43%;
  display: none;
  align-items: center;
  justify-content: center;
}

.Left img {
  width: 100%;
}

.Right {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.Header {
  color: var(--color);
  font-size: 30px;
  font-family: Source Code Pro,monospace;
  text-align: center;
}

.Text {
  font-size: 13px;
  color: black;
  font-family: Space Mono;
  text-align: justify;
}

.Text span {
  color: var(--color);
}

.LogoGroup {
  margin-top: 25px;
}

.LogoGroup img{
  width: 100%;
}

.WelcomeSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 6%;
}

.WelcomeSection img {
  width: 150%;
}

* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Left {
    display: flex;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .Section {
    flex-direction: row;
  }
  
  .Right {
    width: 50%;
  }

  .Header {
    font-size: 2.1vw;
    text-align: left;
  }
  
  .Text {
    font-size: 1vw;
  }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
