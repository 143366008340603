.NavBar {
  width: 100%;
  display: flex;
  position: fixed;
  z-index: 1;
}

.NavBar.WelcomePage {
  bottom: 0;
  padding-right: 8%;
}

.NavBar.WelcomePage .Tabs button:first-child {
  display: none;
}

.Tabs {
  display: flex;
  flex-grow: 1;
  min-height: 50px;
  align-items: center;
  max-width: 92%;
}

.Tab {
  display: flex;
  justify-content: center;
  width: 25%;
  height: 100%;
  color: var(--color, white);
  transition: background 0.7s;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
}

.Tab:hover {
  color: white;
  background: var(--color, white);
}

.Tab.active {
  color: white;
  background: var(--color, white);
}

.PLogo{
  width: 24px;
}

.LangTabs {
  width: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  position: fixed;
  top: 0;
  right: 0;
}

.LangTabs button:first-child {
  border-bottom: 1px solid black;
}

.Lang {
  color: black;
  flex-grow: 1;
  height: fit-content;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.Lang.active {
  color: #9e0000;
}

.Lang:hover {
  text-decoration: underline;
}

.logo {
  height: 12%;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .NavBar.WelcomePage button:first-child {
    display: flex;
  }
  .NavBar.WelcomePage button:first-child img {
    display: none;
  }

  .Tab {
    width: 20%;
    font-size: 1.2vw;
    padding: 1% 0;
  }

  .LangTabs {
    flex-direction: row;
    position: fixed;
    top: 0;
    right: 0;
    padding: .5% 0;
  }

  .LangTabs button:first-child {
    border-bottom: none;
    border-right: 1px solid black;
  }

  .Lang {
    font-size: .8vw;
    border-left: none;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}