.ContactUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Top {
  display: flex;
  height: 50%;
  justify-content: space-around;
  align-items: center;
  padding: 0 4%;
  flex-direction: column;
}

.Bottom {
  height: 50%;
  width: 100%;
  display: none;
}

.ContactInfo {
  color: #969ca0;
  font-size: 18px;
  font-family: Space Mono;
  text-align: left;
}

.Info {
  display: flex;
  align-items: center;
  font-size: 11px;
}

.Info img {
  height: 100%;
}

.Info p {
  margin-left: 10px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Bottom {
    display: block;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .Top {
    flex-direction: row;
  }

  .ContactUs .Text {
  width: 47%;
  }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}