@import url('https://fonts.googleapis.com/css?family=Space+Mono:400,700&subset=latin-ext');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --green: #52875d;
  --blue: #3280c2;
  --red : #c63d43;
  --yellow : #dfdc02;
  --orange: #EF8F20;
  --lightblue: #66CDDB;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background-color: white;
  border: none;
}

button:active, button:focus {
  outline: none;
}

.design {
  --color: var(--green);
}

.technology {
  --color: var(--blue);
}

.about-us {
  --color: var(--red);
}

.contact-us {
  --color: var(--yellow);
}

.orange {
  --color: var(--orange);
}

.lightblue {
  --color: var(--lightblue);
}