.Section {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  color: var(--color);
  align-items: center;
  justify-content: center;
}

.Left {
  width: 43%;
  display: none;
  align-items: center;
  justify-content: center;
}

.Left img {
  width: 100%;
}

.Right {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.Header {
  color: var(--color);
  font-size: 30px;
  font-family: Source Code Pro,monospace;
  text-align: center;
}

.Text {
  font-size: 13px;
  color: black;
  font-family: Space Mono;
  text-align: justify;
}

.Text span {
  color: var(--color);
}

.LogoGroup {
  margin-top: 25px;
}

.LogoGroup img{
  width: 100%;
}

.WelcomeSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 6%;
}

.WelcomeSection img {
  width: 150%;
}

* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Left {
    display: flex;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .Section {
    flex-direction: row;
  }
  
  .Right {
    width: 50%;
  }

  .Header {
    font-size: 2.1vw;
    text-align: left;
  }
  
  .Text {
    font-size: 1vw;
  }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}